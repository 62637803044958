import React, { Component } from "react";
import { IScreenData as ScreenData } from "../../components/Screen";
import { IMeasurements as Measurements } from "../../components/Calipers"; 
import Teletype, { ITeletypeData as TeletypeData } from "../../components/Teletype";
import Prompt from "./Prompt";
import Keypad from "./Keypad";

// asset loading
import "../../assets/css/anagrams.scss";
import wordlist from "../../data/wordlist.json";

interface Props {
    // props
    measurements: Measurements;
    sound: boolean;

    // game events
    onRendered: ()=> void;
    onSuccess: (id: string) => void;
    onFailure: () => void;
}

interface State {
    // game variables
    input: string;

    // render states
    headingRendered: boolean;
    promptRendered: boolean;
    keypadRendered: boolean;

    // game state
    ready: boolean;
}

// screen sizes
const SCREEN_COLS = 54;
const SCREEN_ROWS = 16;

// game constants
const PROMPT = ">";
const PASSWORD_CHAR = "*";
const PASSWORD_MAX_LENGTH = 4;


export default class Game extends Component<Props, State> {
    private _wordlist = wordlist;
    private _screen: ScreenData = {
        id: "0",
        content: [
            {
                text: "Welcome to ROBCO Industries (TM) Termlink",
            },

            {
                text: "Password Required",
            }
        ],
    };
    private _passwords = ["able", "bits", "read"];
    private _targets = ["94913fcb-4d8e-4314-b99e-e24646d5e551", "372b500a-4880-46b5-b23b-a490c74d4f37", "3b87ab4f-fe40-4b1f-9dfa-252a78f790fb"];
    private _letters: string[] = null;

    private _prompt: string;

    constructor(props: Props) {
        super(props);

        // init
        this._initializeGame();

        // state
        this.state = {
            input: this._prompt,

            headingRendered: false,
            promptRendered: false,
            keypadRendered: false,

            ready: false,
        }

        // method binding
        this._onButtonClick = this._onButtonClick.bind(this);
    }

    public componentDidMount(): void {
        
    }

    public componentDidUpdate(): void {
    }

    public render(): React.ReactElement {
        const {
            charWidth,
            lineHeight,
        } = this.props.measurements;

        const {
            headingRendered,
            promptRendered,
            keypadRendered,
        } = this.state;
        
        // screen width hasn't been calculated yet;
        // nothing to render
        if (!charWidth) {
            return <div />;
        }

        const style: React.CSSProperties = {
            maxHeight: lineHeight * SCREEN_ROWS,
        };

        let classNames = ["screen", "anagrams"];
        if (keypadRendered) {
            classNames.push("ready");
        } 

        return (
            <section className={classNames.join(" ")} style={style}>
                <header>
                    {/* screen heading */}
                    {this._renderHeading()}
                </header>

                <section>
                    {/* password input display */}
                    {headingRendered && this._renderPrompt()}

                    {/* attempts remaining */}
                    {promptRendered && this._renderKeypad()}
                </section>
            </section>
        );
    }

    // heading
    private _renderHeading(): React.ReactElement {
        const { sound } = this.props;
        return (
            <Teletype
                data={this._screen.content}
                autostart={true}
                sound={sound}
                onDone={() => this.setState({ headingRendered: true, })}
                onNavigate={null}
            />
        );
    }

    // password prompt field
    private _renderPrompt(): React.ReactElement {
        const {
            input,
            promptRendered,
            ready,
        } = this.state;

        const {
            sound
        } = this.props;
        
        const data: TeletypeData[] = [{
            text: PROMPT + input.toUpperCase(),
        }];

        if (!ready) {
            return (
                <Teletype
                    data={data}
                    autostart={true}
                    sound={sound}
                    onDone={() => this.setState({ promptRendered: true, })}
                    onNavigate={null}
                    animate={!promptRendered}
                />
            );
        }

        return (
            <Prompt
                prompt={PROMPT}
                text={input.toUpperCase()}
                onRendered={null}
            />
        );
    }

    // keypad
    private _renderKeypad(): React.ReactElement {
        const measurements = this.props.measurements;

        return (
            <Keypad
                keys={this._letters}
                onClick={this._onButtonClick}
                onRendered={() => this.setState({
                    keypadRendered: true,
                    ready: true,
                })}
                measurements={measurements}
            />
        );
    }

    private _onButtonClick(text: string): void {
        const {
            input,
        } = this.state;

        let letters = input;

        // delete pressed?
        if (text === "del") {
            let index = input.indexOf(PASSWORD_CHAR);

            // at the start of the string; do nothing
            if (index === 0) {
                return;
            }

            // at the end of the string;
            if (index === -1) {
                index = input.length;
            }

            letters = letters.substr(0, index - 1) + PASSWORD_CHAR + letters.substr((index - 1) + PASSWORD_CHAR.length);

            this.setState({
                input: letters,
            });

            return;
        }

        if (text === "ok") {
            this._checkAnswer();
            return;
        }

        // eneter pressed?

        // if we're reacting to text that isn't in
        // the letter collection, do nothing
        if (this._letters.indexOf(text) === -1) {
            console.log("hey! ", text)
            return;
        }

        letters = letters.replace(PASSWORD_CHAR, text);
        
        this.setState({
            input: letters,
        });
    }

    private _checkAnswer(): void {
        const {
            input,
        } = this.state;

        const regex = new RegExp(`"${PASSWORD_CHAR}"`, "g");
        const answer = input.toLowerCase().replace(regex, "");

        const index = this._passwords.indexOf(answer);
        console.log(index);
        if (index > -1) {
            // we have an answer!
            const target = this._targets[index];
            this.props.onSuccess(target);
        } else {
            // TODO: handle errors
        }
    }

    // game preparation
    // array shuffling, cf. https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
    private _shuffle(source: any[]) {
        let copy = [...source];

        for (let i = copy.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
            [copy[i], copy[j]] = [copy[j], copy[i]]; // swap elements
        }

        return copy;
    }

    private _initializeGame(): void {
        // get an array of unique letters from the passwords
        const passwords = this._passwords.join("");
        const unique = passwords.split("").filter((value, index, array) => {
            return array.indexOf(value) === index;
        });
        this._letters = this._shuffle(unique);

        let prompt = "";
        for (let i = 0; i < PASSWORD_MAX_LENGTH; i++) {
            prompt += PASSWORD_CHAR;
        }
        this._prompt = prompt;
    }

    private _ready(): boolean {
        // check the rendered states
        const {
            headingRendered,
            promptRendered,
            keypadRendered: buttonsRendered,
        } = this.state;

        return (
            headingRendered
            && promptRendered
            && buttonsRendered
        );
    }
}