import React, { Component } from "react";
import Screen, { IScreenData } from "./components/Screen";
import Scanlines from "./components/Scanlines";
import Calipers, { IMeasurements } from "./components/Calipers";
import Mastermind from "./minigames/Mastermind/Game";
import Anagrams from "./minigames/Anagrams/Game";
import Loader from "./components/Loader";
import Spinner from "./components/Spinner";

// webfont loader
import FontFaceObserver from "fontfaceobserver";

// story data
// import story from "./data/story.json";
import story from "./data/emma.json";

interface State {
    ready: boolean;
    loaded: boolean;

    // app state
    currentScreen: string;
    previousScreen: string;
    unlocked: boolean;

    // dimensions
    screenWidth: number;
    screenHeight: number;
    measurements: IMeasurements;
}

interface IStoryConfig {
    hacking: boolean;
    anagrams: boolean;
}

interface IStory {
    config: any;
    screens: IScreenData[];
}

// app-wide setting dictated by screenshots
// from fallout 4
const MAX_COLUMNS = 54;

class App extends Component<any, State> {
    private _mainRef: React.RefObject<HTMLElement>;
    private _story: IStory;

    constructor(props?: any) {
        super(props);

        this._story = story;

        // create the refs
        this._mainRef = React.createRef<HTMLElement>();

        // initialize the first screen
        this.state = {
            loaded: false,
            ready: false,

            currentScreen: "94913fcb-4d8e-4314-b99e-e24646d5e551",
            previousScreen: null,
            unlocked: this._story.config.anagrams ? false : true,

            screenWidth: null,
            screenHeight: null,
            measurements: null,
        };

        this._changeScreen = this._changeScreen.bind(this);
        this._resizeScreen = this._resizeScreen.bind(this);
        this._onUnlocked = this._onUnlocked.bind(this);
    }

    public componentDidMount() {
        this._loadFonts([
            "DosFont",
            "FixedSys",
        ]);
    }

    public componentDidUpdate() {
        
    }

    public render() {
        const {
            loaded,
            ready,
            screenWidth,
            screenHeight,
            measurements,
        } = this.state;

        const lineheight = measurements && measurements.lineHeight || 0;
        const charWidth = measurements && measurements.charWidth || 0;

        const style = {
            width: screenWidth,
            height: screenHeight,
            paddingTop: lineheight,
            paddingLeft: charWidth,
            paddingRight: charWidth,
        };

        return (
            <main ref={this._mainRef} style={style}>
                {ready && screenWidth && this._renderScreen()}

                {!ready && <Spinner />}
                {loaded && this._renderCalipers()}
                <Scanlines />
            </main>
        );
    }

    private _renderCalipers(): React.ReactElement {
        return (<Calipers onMeasured={this._resizeScreen} />);
    }

    private _renderScreen(): React.ReactElement {
        const screenData = this._getScreenData();
        const {
            screenWidth,
            unlocked,
            measurements,
            previousScreen,
        } = this.state;
        
        const sound = false;
        measurements.screenWidth = screenWidth;

        if (!unlocked) {
            return (
                <Anagrams
                    measurements={measurements}
                    sound={sound}
                    onRendered={() => console.log("onLoad")}
                    onSuccess={(index) => {
                        this.setState({
                            unlocked: true,
                        }, () => this._changeScreen(index));
                    }}
                    onFailure={() => console.log("onFailure")}
                />
            );
        }

        console.log(screenData);
        
        return (
            <Screen
                data={screenData}
                previous={previousScreen}
                onChangeScreen={this._changeScreen}
                width={screenWidth}
                sound={sound}
            />
        );
    }

    private _getScreenData(): IScreenData {
        const {
            currentScreen,
        } = this.state;

        // find the current screen object
        const screen = story.screens.find((item) => item.id === currentScreen);
        console.log(screen);
        return screen || {
            id: null,
            content: null,
        }
    }

    private _changeScreen(id: string) {
        const currentScreen = this.state.currentScreen;

        this.setState({
            currentScreen: id,
            previousScreen: currentScreen,
        });
    }

    private _resizeScreen(measurements: IMeasurements): void {
        const {
            charWidth,
            lineHeight,
            columns,
            rows,
        } = measurements;

        const screenWidth = charWidth * (Math.min(columns, MAX_COLUMNS) - 2);
        const screenHeight = lineHeight * (rows - 2);

        this.setState({
            ready: true,
            screenWidth,
            screenHeight,
            measurements,
        });
    }

    private _onUnlocked(): void {
        this.setState({
            unlocked: true,
        });
    }

    // load the fonts prior to performing measurements
    private _loadFonts(fonts: string[]): void {
        const promises = fonts.map((font: string) => {
            return new FontFaceObserver(font).load(null, 7500);
        });

        // load the fonts and proceed
        Promise
            .all(promises)
            .then((result: any) => {})
            .catch((error: any) => {
                console.error(error);
            })
            .finally(() => {
                // regardless of what happens, we should proceed
                this.setState({
                    loaded: true,
                });
            });
    }
}

export default App;
