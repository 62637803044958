import React, { Component } from "react";

interface Props {
    prompt?: string;
    text: string;
    onRendered?: () => void;
}

export default class Prompt extends Component<Props, any> {
    public componentDidMount() {
        if (this.props.onRendered) {
            this.props.onRendered();
        }
    }

    public render() {
        return (
            <div className="prompt">
                {this._renderPrompt()}
                {this._renderText()}
            </div>
        );
    }

    private _renderPrompt(): React.ReactElement {
        const { prompt } = this.props;
        if (!prompt) {
            return null;
        }

        return (
            <span>{prompt}</span>
        );
    }

    private _renderText(): React.ReactElement {
        const {
            text,
        } = this.props;

        const letters = text.split("");
        const asterisk = letters.indexOf("*");
        const elements = letters.map((value, index) => <span key={index}>{value}</span>);
        // no asterisks in string, assume the cursor is at the end
        if (asterisk === -1) {
            elements.push(<span className="cursor empty" key="empty">#</span>);
        } else {
            elements[asterisk] = <span className="cursor" key="cursor">*</span>;
        }

        return (
            <span>{elements}</span>
        );
    }
}
