import React, { Component } from "react";
import Link from "./Link";

// sound effects
import NewLineSound from "../assets/audio/scroll.wav";

export interface ITeletypeProps {
    data: ITeletypeData[];
    autostart: boolean;
    paused?: boolean;
    sound?: boolean;
    animate?: boolean;
    
    // events
    onNavigate: (id: string) => void;
    onDone?: () => void;
    onScroll?: (value: number) => void;
    
    renderStatic?: () => React.ReactNode;
}

export interface ITeletypeState {
    index: number;
    char: number;
    active: boolean;
    done: boolean;
    paused: boolean;
}

export interface ITeletypeData {
    text: string;
    target?: string;
}

class Teletype extends Component<ITeletypeProps, ITeletypeState> {
    private _cursorInterval = 10;
    private _animateTimerId: number | null = null;
    private _cursorRef: React.RefObject<HTMLElement> = null;
    private _cursorY: number = null;

    // sound effects
    private _sound: boolean = false;
    private _newlineSound: HTMLAudioElement;

    constructor(props: ITeletypeProps) {
        super(props);

        const done = (this.props.animate === false);

        this.state = {
            index: 0,
            char: 0,
            active: false,
            done,
            paused: !!this.props.paused,
        };

        this._cursorRef = React.createRef<HTMLElement>();
        this._cursorY = 0;

        this._sound = false;// this.props.sound === false ? false : true;
        if (this._sound) {
            this._newlineSound = new Audio(NewLineSound);
        }

        this._animate = this._animate.bind(this);
        this._updateState = this._updateState.bind(this);
        this._onDone = this._onDone.bind(this);
        this._unpause = this._unpause.bind(this);
    }

    public componentDidMount() {
        if (!this.props.autostart) {
            return;
        }

        if (this.state.done) {
            return;
        }

        this._animate();
    }

    public componentDidUpdate(prevProps: ITeletypeProps, prevState: ITeletypeState) {
        // check if the animate prop has changed
        if (prevProps.animate !== this.props.animate) {
            const done = (this.props.animate === false);

            this.setState({
                done,
            });

            return;
        }

        if (prevProps.data !== this.props.data && (this.props.animate !== false)) {
            this._reset();
            return;
        } 

        if (!prevState.done && this.state.done) {
            this._onDone();
        }

        if (this.state.done) {
            return;
        }

        this._animate();
    }

    public render() {
        return (
            <section className="teletype-container" onClick={this._unpause}>
                {!this.state.done && this._renderAnimatedElements()}
                {this.state.done && this._renderStaticElements()}
            </section>
        );
    }

    private _renderTextElements() {
        const data = this.props.data;
        return data.map((item, index) => {
            return <div key={index}>{item.text.trimEnd()}</div>
        });
    }

    private _animate() {
        if (this._animateTimerId) {
            clearTimeout(this._animateTimerId);
        }

        if (this.state.paused) {
            return;
        }

        // track the current active line
        this._getCursorPosition();

        // setTimeout is preferred over requestAnimationFrame so the interval
        // can be specified -- we can control how janky it looked; requestAnimationFrame 
        // results in animation that's much to smooth for our purposes.
        this._animateTimerId = window.setTimeout(this._updateState, this._cursorInterval);
    }

    private _updateState() {
        if (!this.state) {
            return;
        }

        const {
            index,
            char,
            active,
            done,
            paused,
        } = this.state;
        
        if (done) {
            return;
        }

        let nextIndex = index;
        let nextChar = char;
        let nextActive = active;
        let nextDone = done;
        let nextPaused = paused;

        const data = this.props.data;

        // check the current string length and compare it to the char
        const currentText = data[index].text;
        
        // if we're not active, we are now!
        if (!nextActive) {
            nextActive = true;
        }

        // if char is less that the current string, increment it
        if (char < currentText.length) {
            nextChar = char + 1;
        } else {
            // if char equals the string length, set it to 0 and 
            // make the next string active
            if (index < data.length - 1) {
                nextIndex = index + 1;
                nextChar = 0;
            } else {
                nextActive = false;
                nextDone = true;
            }
        }

        // update state
        this.setState({
            index: nextIndex,
            char: nextChar,
            active: nextActive,
            done: nextDone,
            paused: nextPaused,
        });
    }

    private _renderAnimatedElements() {
        const {
            index,
            char,
            active,
        } = this.state;
        
        if (!active) {
            return;
        }

        // const text = this.props.text[index];
        const text = this.props.data[index].text;
        const visible = text.substr(0, char);
        const cursor = text.substr(char, 1);
        const hidden = text.substr(char + 1);

        let renderedElements: ITeletypeData[] = [];
        if (index > 0) {
            renderedElements = this.props.data.slice(0, index);
        }

        return (
            <div className="teletype-animated">
                {renderedElements.map((item, index) => {
                    return <div className="rendered" key={index}>{item.text}</div>;
                })}
                <div className="active">
                    <span className="visible">{visible}</span>
                    <span className="cursor" ref={this._cursorRef}>{cursor}</span>
                    <span className="hidden">{hidden}</span>
                </div>
            </div>
        );
    }

    private _renderStaticElements() {
        // override static element renderer
        if (this.props.renderStatic) {
            return this.props.renderStatic();
        }

        // const text = this.props.text;
        const data = this.props.data;
        
        return (
            <div className="teletype-static">
                {data.map((item, index) => {
                    const text = item.text;
                    if (item.target) {
                        return (
                            <Link
                                key={index}
                                text={text}
                                target={item.target}
                                sound={this._sound}
                                onClick={this.props.onNavigate}
                            />
                        );
                    }

                    return (
                        <div className="rendered" key={index}>{text}</div>
                    );
                })}
            </div>
        );
    }

    private _onDone(): void {
        if (this.state.done && this.props.onDone) {
            this.props.onDone();
        }
    }

    private _reset(): void {
        this.setState({
            index: 0,
            char: 0,
            active: this.props.autostart,
            done: false,
        });
    }

    private _pause(): void {
        this.setState({
            paused: true,
        });
    }

    private _unpause(): void {
        this.setState({
            paused: false,
        });
    }

    private _getCursorPosition(): void {
        // get the cursorRef
        const ref = this._cursorRef;
        let y = this._cursorY;

        if (ref && ref.current) {
            const node = ref.current;
            const top = node.offsetTop;
            if (y !== top) {
                // new line
                this._cursorY = top;
                this._playSound(this._newlineSound);
                if (this.props.onScroll) {
                    this.props.onScroll(top);
                }
            }
        }
    }

    private _playSound(sound: HTMLAudioElement): void {
        if (!this._sound) {
            return;
        }

        sound.play();
    }

    private _paginate(): void {
        // TODO: determine if curosr is off-screen
        // and if so, pause() and show more button
    }
}

export default Teletype;
