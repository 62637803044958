import React, { Component } from "react";

// sound effects
import ClickSound from "../assets/audio/click.wav";
import HoverSound from "../assets/audio/hover.wav";

export interface ILinkProps {
    text: string;
    target: string;
    sound?: boolean;
    onClick?: (target: string) => void;
}

class Link extends Component<ILinkProps> {
    private _sound: boolean;

    // sound effects
    private _clickSound: HTMLAudioElement;
    private _hoverSound: HTMLAudioElement;

    constructor(props: ILinkProps) {
        super(props);

        this._sound = this.props.sound === false ? false : true;
        if (this._sound) {
            this._clickSound = new Audio(ClickSound);
            this._hoverSound = new Audio(HoverSound);
        }

        this._click = this._click.bind(this);
        this._down = this._down.bind(this);
        this._hover = this._hover.bind(this);
    }

    public render() {
        return (
            <div className="rendered teletype-link">
                <span
                    onClick={this._click}
                    onMouseDown={this._down}
                    onMouseOver={this._hover}
                >
                    {this.props.text}
                </span>
            </div>
        );
    }

    private _click(): void {
        const {
            target,
            onClick,
        } = this.props;

        if (onClick) {
            // if (this._sound) {
            //     this._clickSound.onended = () => onClick(target);
            //     this._playSound(this._clickSound);

            //     return;
            // }

            onClick(target);
        }
    }

    private _down(): void {
        this._playSound(this._clickSound);
    }

    private _hover(): void {
        this._playSound(this._hoverSound);
    }

    private _playSound(sound: HTMLAudioElement): void {
        if (!this._sound) {
            return;
        }
        
        sound.play();
    }
}

export default Link;
