import React, { Component } from "react";
import "../assets/css/calipers.scss";


interface Props {
    onMeasured?: (measurements: IMeasurements) => void;
    onResize?: (measurements: IMeasurements) => void;
}

export interface IMeasurements {
    charWidth: number;
    charHeight: number;
    lineHeight: number;

    screenWidth?: number;
    screenHeight?: number;

    columns?: number;
    rows?: number;
}

interface State extends IMeasurements {
    ready?: boolean;
    measured?: boolean;
}

const MAX_COLUMNS = 54;

export default class Calipers extends Component<Props, State> {
    private _lineRef: React.RefObject<HTMLDivElement> = null;
    private _textRef: React.RefObject<HTMLElement> = null;
    private _text = "WWWWWWWWWW";

    private _resizeTimerId: number = null;
    private _resizeTimerInterval = 150;

    constructor(props: Props) {
        super(props);

        this._lineRef = React.createRef<HTMLDivElement>();
        this._textRef = React.createRef<HTMLElement>();

        this.state = {
            screenWidth: null,

            // text sizes
            charWidth: null,
            charHeight: null,
            lineHeight: null,
            
            // row & column count
            columns: null,
            rows: null,

            // have we performed our initial measurement?
            measured: false,
        };

        this._onMeasured = this._onMeasured.bind(this);
        this._onResize = this._onResize.bind(this);

        this._measureViewport = this._measureViewport.bind(this);
    }

    public componentDidMount(): void {
        // measure the various elements
        this._getMeasurements();

        // attach the resize event listener
        window.addEventListener("resize", this._onResize);
    }

    public componentWillUnmount() {
        this._clearResizeTimer();
        window.removeEventListener("resize", this._onResize);
    }

    public render() {
        return (
            <section className="calipers">
                <div className="line" ref={this._lineRef}>
                    <span className="text" ref={this._textRef}>{this._text}</span>
                </div>
            </section>
        );
    }

    private _getMeasurements(): void {
        // get references to the dom elements
        const line = this._lineRef.current;
        const text = this._textRef.current;

        // nothing to do if they don't exist
        if (!line || !text) {
            return;
        }

        // measure the lineheight
        const lineHeight = line.offsetHeight;

        // measure the character height
        const charHeight = text.offsetHeight;

        // measure a single character width (average)
        const charWidth = text.offsetWidth / this._text.length;
        
        // TODO: remove
        // multiple that by MAX_COLUMNS
        const screenWidth = charWidth * MAX_COLUMNS;

        // measure the viewport (this is also done on resize)
        // get the width & height in terms of rows and columns
        const columns = this._getColumns(charWidth);
        const rows = this._getRows(lineHeight);

        // update state and notify the app (via `onMeasured`)
        this.setState({
            screenWidth,
            charWidth,
            charHeight,
            lineHeight,
            columns,
            rows,
        }, this._onMeasured);
    }

    private _onMeasured(): void {
        const {
            charHeight,
            charWidth,
            lineHeight,
            columns,
            rows,
        } = this.state;

        // create the sizes object
        const measurements: IMeasurements = {
            charHeight,
            charWidth,
            lineHeight,
            columns,
            rows,
        };

        // clear the interval, if it still exists
        // if (this._measurementTimerId) {
        //     this._clearTimer();
        // }

        if (this.props.onMeasured) {
            this.props.onMeasured(measurements);
        }

        // ready to go!
        this.setState({
            measured: true,
        })
    }

    private _measureViewport(): void {
        const {
            charWidth,
            lineHeight,
            measured,
        } = this.state;

        if (!measured) {
            return;
        }

        console.log("measuring viewport");

        // get the width & height in terms of rows and columns
        const columns = this._getColumns(charWidth);
        const rows = this._getRows(lineHeight);

        this.setState({
            columns,
            rows,
        }, this._onMeasured);
    }

    private _getColumns(columnWidth: number): number {
        if (!columnWidth) {
            return null;
        }

        const viewportWidth = document.documentElement.clientWidth;
        return Math.floor(viewportWidth / columnWidth);
    }

    private _getRows(rowHeight: number): number {
        if (!rowHeight) {
            return null;
        }

        const viewportHeight = document.documentElement.clientHeight;
        console.log(viewportHeight, rowHeight, viewportHeight / rowHeight);
        return Math.floor(viewportHeight / rowHeight);
    }

    private _setResizeTimer(): void {
        this._resizeTimerId = window.setTimeout(
            this._measureViewport,
            this._resizeTimerInterval,
        );
    }

    private _clearResizeTimer(): void {
        if (this._resizeTimerId) {
            console.log("clearing resize timer");
            window.clearTimeout(this._resizeTimerId);
        }
    }

    private _onResize(e: Event): void {
        this._clearResizeTimer();
        this._setResizeTimer();
    }
}
