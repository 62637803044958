import React, { Component } from "react";
import "../assets/css/spinner.scss";

export default class Spinner extends Component<any, any> {
    public render() {
        return (
            <section className="loader">
                <div className="spinner" />
            </section>
        );
    }
}