import React, { Component, CSSProperties as CSS } from "react";
import { IMeasurements as Measurements } from "../../components/Calipers";
import Teletype, { ITeletypeData as TeletypeData } from "../../components/Teletype";

interface Props {
    keys: string[];
    measurements: Measurements;
    sound?: boolean;
    onClick: (label: string) => void;
    onRendered: () => void;

    // should we render the teletype or not?
    animate?: boolean;
}

interface State {
    teletypeRendered: boolean;
    keypadRendered: boolean;
}

const BUTTONS_PER_ROW = 3;
const BUTTON_WIDTH = 5;

const DELETE_LABEL = "del";
const SUBMIT_LABEL = "ok";

export default class Keypad extends Component<Props, State> {
    private _data: TeletypeData[] = [];

    constructor(props: Props) {
        super(props);

        // binding
        this._onButtonClick = this._onButtonClick.bind(this);

        this.state = {
            teletypeRendered: (props.animate === false),
            keypadRendered: false,
        };
    }

    public componentDidMount() {
        // if (this.props.onRendered) {
        //     this.props.onRendered();
        // }
    }

    public componentDidUpdate(prevProps: Props, prevState: State): void {
        console.log("componentDidUpdate", this.state);
        const {
            teletypeRendered,
        } = this.state;

        // we're done
        if (!prevState.teletypeRendered && teletypeRendered) {
            this.props.onRendered();
        }
    }

    public render() {
        const {
            teletypeRendered,
        } = this.state;

        if (!teletypeRendered) {
            return this._renderTeletype();
        }

        return this._renderKeypad();
    }

    private _renderTeletype(): React.ReactElement {
        const {
            keys,
            measurements,
            sound,
        } = this.props;

        const {
            screenWidth,
            charWidth,
        } = measurements;

        // TODO: determine left padding
        const columns = Math.floor(screenWidth / charWidth);
        const columnsLeft = Math.floor((columns - (BUTTONS_PER_ROW * BUTTON_WIDTH)) / 2) - 1;
        const marginLeft = this._padString("", columnsLeft, 0);

        // generate the data
        let data: TeletypeData[] = [];
        const letters = keys.map(value => this._generateLabel(value).toUpperCase());

        // delete, ok buttons (and spacer)
        const actions = [DELETE_LABEL, "", SUBMIT_LABEL].map(value => this._generateLabel(value).toUpperCase());

        // push the button rows into the data array
        for (let i = 0; i < letters.length; i += BUTTONS_PER_ROW) {
            const row = letters.slice(i, i + BUTTONS_PER_ROW).join("");
            data.push({
                text: marginLeft + row,
                target: null,
            });
        }

        // add the actions to the data array
        data.push({
            text: marginLeft + actions.join(""),
            target: null,
        });

        return (
            <Teletype
                data={data}
                autostart={true}
                sound={!!sound}
                onDone={() => this.setState({ teletypeRendered: true, })}
                onNavigate={null}
            />
        );
    }

    private _renderKeypad(): React.ReactElement {
        const {
            keys,
        } = this.props;

        const {
            charWidth,
            screenWidth,
        } = this.props.measurements;

        const columns = Math.floor(screenWidth / charWidth);
        const columnsLeft = Math.floor((columns - (BUTTONS_PER_ROW * BUTTON_WIDTH)) / 2);
        const marginLeft = Math.floor(columnsLeft * charWidth);

        const style = {
            width: Math.ceil(BUTTONS_PER_ROW * (BUTTON_WIDTH * charWidth)),
            marginLeft,
        };

        return (
            <section className="keypad" style={style}>
                <div className="buttons letters">
                    {keys.map((value, index) => {
                        return this._renderButton(value, index);
                    })}
                </div>

                <div className="buttons actions">
                    {this._renderButton(DELETE_LABEL, 1, "delete")}
                    {this._renderButton(SUBMIT_LABEL, 2, "submit")}
                </div>
            </section>
        );
    }

    private _renderButton(label: string, index: number, className?: string): React.ReactElement {
        const {
            charWidth,
        } = this.props.measurements;

        const handleTouchStart = (e: React.TouchEvent<HTMLButtonElement>) => {
            this._onButtonClick(label);
        };

        const handleTouchEnd = (e: React.TouchEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.currentTarget.blur();
        }

        const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            this._onButtonClick(label);
        };

        // determine an exact size for the buttons
        // min-width should be 5 cols
        const style: CSS = {
            // width: charWidth * BUTTON_WIDTH,
        };

        return (
            <button
                key={index}
                onClick={handleClick}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                style={style}
                className={className}
            >
                <div className="label">
                    {this._generateLabel(label)}
                </div>
            </button>
        );
    }

    private _generateLabel(label: string): string {
        // determine left & right padding
        const paddingLeft = Math.ceil((BUTTON_WIDTH - label.length) / 2);
        const paddingRight = BUTTON_WIDTH - label.length - paddingLeft;

        return this._padString(label, paddingLeft, paddingRight);
    }

    private _padString(text: string, start: number, end: number): string {
        const _start = start;
        const _end = end;

        const pad = " ";
        // start padding
        do {
            text = pad + text;
            start--;
        } while (start > 0);

        // end padding
        do {
            text = text + pad;
            end--;
        } while (end > 0);

        return text;
    }

    // events
    private _onButtonClick(label: string): void {
        this.props.onClick(label);
    }
}